.carousel-container {
  width: 852px;
  height: 480px;
  max-width: 85vw;
  max-height: 48vw;
  margin: 0px;
  margin-bottom: 20px;
  position: relative;
  margin:auto;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.slide {
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
  transition: opacity ease-in-out 0.4s;
}
.slide img {
  width: 100%;
  height: 100%;
  object-fit: scale-down;
}
.active-anim {
  opacity: 1;
}

.image-slide {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #00000000;
  border: 0px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.image-slide img {
  width: 40px;
  height: 40px;
  pointer-events: none;
}
.prev {
  top: 50%;
  left: 20px;
  transform: translate(-150%, -60%);
}
.next {
  top: 50%;
  right: 20px;
  transform: translate(150%, -60%);
}
@media screen and (max-width: 1024px){
  .next {
    top: 100%;
    right: 50px;
    width: 50px;
    transform: none;
    transform: translateX(-50%);
    left: 50%;
    margin: 0 60px;
  }
  .prev {
      top: 100%;
      left: 50px;
      width: 50px;
      transform: none;
      transform: translateX(-50%);
      left: 50%;
      margin: 0 -60px;
  }
  .carousel-container {
      margin-bottom: 50px;
  }
}

.container-dots {
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
}
.dot {
  width: 13px;
  height: 13px;
  border-radius: 50%;
  margin: 0 5px;
  background: #f1f1f1;
}
.dot.active {
  background: rgb(163, 163, 163);
  
}