@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@500&family=Quicksand:wght@300;500;700&display=swap');


/* core selectors */

main {
	margin: 0 auto;
	max-width: 90%;
  padding: 20px 30px;
}

:root {
  --highlight: #4381aa;
  --highlight-hover: #3290cf;
  --light: #e2e2e2;
  --light-gray: #a6a6a6;
  --dark: #000000;
  --darker: #555555;
  --background: #282c34;
  --header-background: #384b59;
  --spacing-one: 0.5vw;
  --spacing-two: 1vw;
  --spacing-five: 3vw;
}

body {
  overflow-y: scroll; /* Show vertical scrollbar */
  width: 100%;
  height: 100%;
  position: relative;
  margin: 0;
  font-weight: 500;
  font-size: 1.2rem;
  color: var(--light);
  font-family: 'Quicksand', sans-serif;
}

/* html tags */
html {
  scroll-behavior: smooth;
}

h1 {
  color: var(--light);
}

h2 {
  font-size: calc(2rem + 2vw);
  font-weight: 300;
  margin: 0;
}

h3 {
  font-size: calc(1rem + 2vw);
  font-weight: 500;
  margin: 0;
  color: black;
}

p {
  font-size: 1.2rem;
  margin: 1em;
}

ul {
  margin-top: 0em;
  padding-inline-start: 0;
}

li {
  margin-top: 1em;
  list-style: none;
  text-align: left;
}

header a {
  color: var(--light);
  text-decoration: initial;
}

section {
  display: grid;
  justify-content: center;
  align-content: center;
}

/* profile styling */

.profile {
  border-radius: 50%;
  width: 25vmin;
  object-fit: cover;
  box-shadow: 0px 0px 30px 10px rgba(0,0,0,0.2);
}

.profile-row {
  align-items: center;
  justify-content: center;
  padding: 10px;
  padding-bottom: 150px;
}

.profile-row h2 {
  margin-left: var(--spacing-five);
}

.profile-row p {
  margin-top: 0;
  font-size: 1.6rem;
  margin-left: var(--spacing-five);
}

/* footer styling */
.page-container {
  position: relative;
  height: fit-content;
  min-height: calc(100vh - 200px);
  padding-bottom: 150px;
}

.footer {
  position: absolute;
  bottom: 0;
  padding: 0;
  margin: 0;
  
  width: 100%;
  text-align: center;
  
  font-family: 'Quicksand', sans-serif;
  font-size: calc(.3rem + 2vmin);
  font-weight: lighter;
  text-transform: capitalize;

  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}

.footer-svg {
  width: 40vw;
  min-width: 300px;
  height: 150px;
}

.footer a{
  color: var(--light);
  text-decoration: initial;
  font-family: 'Quicksand', sans-serif;
  font-size: 1.5rem;
  font-weight: lighter;
  text-transform: capitalize;
  font-size: 2rem;
}

.cls-1{
  fill:var(--header-background);
}

.footer-icon{
  font-size: 60px;
}

.footer ul {
  position: absolute;
  transform: translateY(60px);
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.footer-text {
  fill: white;
}

/* input styling */
.dark-input {
  width: 600px;
  max-width: 80vw;
}

.form-row {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: left;
  align-items: center;  
}

.dark-input input[type="text"]{
  width: 100%;
  height: 3rem;
  border-radius: 10px;
  border-width: 0;
  margin: 5px 0px;
  box-sizing: border-box;

  padding: 1px 10px;
  font-size: 1.5rem;
  color: var(--light-gray);

  background-color: var(--darker);
}

.dark-input input[type="text"]:focus-visible {
  outline: none;
  box-shadow: 0 0 15px var(--header-background);
}

.dark-input input[type="text"]::placeholder{
  color: var(--light-gray);
}

.dark-input input.half {
  margin-right: 10px;
  float: left;
}

.dark-input textarea[type="text"]{
  width: 100%;
  height: 10rem;
  border-radius: 10px;
  border-width: 0;
  margin: 5px 0px;
  padding: 1px 10px;
  font-size: 1.5rem;
  color: var(--light-gray);
  background-color: var(--darker);
  box-sizing: border-box;
  resize: vertical;
}

.dark-input textarea[type="text"]::placeholder{
  color: var(--light-gray);
}

.dark-input textarea[type="text"]:focus-visible {
  outline: none;
  box-shadow: 0 0 15px var(--header-background);
}

input:-internal-autofill-selected {
    appearance: none !important;
    background-image: none !important;
    background-color: var(--darker) !important;
    color: var(--light-gray) !important;
}

input:-webkit-autofill,
input:-webkit-autofill, 
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
textarea:-webkit-autofill, 
textarea:-webkit-autofill:hover, 
textarea:-webkit-autofill:focus, 
select:-webkit-autofill, 
select:-webkit-autofill:hover, 
select:-webkit-autofill:focus {
  border: 0;
  -webkit-text-fill-color: var(--light-gray);
  -webkit-box-shadow: 0 0 0px 1000px var(--darker) inset;
  box-shadow: 0 0 0px 1000px var(--darker) inset;
  -webkit-transition: background-color 5000s ease-in-out 0s;
  -o-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
}

.dark-input .button {
  color: var(--light);
  background-color: var(--header-background);
  border-radius: 5px;
  cursor: pointer;
  width: 200px;
  height: 2rem;
  border-width: 0;
  font-weight: 900;
  line-height: 45px;
  margin: 10px;
  padding: 1px 10px;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 1.5rem;
  font-family: 'Quicksand', sans-serif;
  display: inline;
  line-height: 0px;
  white-space: nowrap;
}

.dark-input .button:hover { text-decoration: none; }

.btn-1 {
  font-weight: 900;
  
  animation-duration: 700ms;
  animation-fill-mode: forwards;
}

.btn-1 svg {
  height: 2rem;
  border-radius: 5px;
  left: 0;
  position: absolute;
  top: 0; 
  width: 100%; 
}

.btn-1 rect {
  fill: none;
  stroke: var(--light);
  stroke-width: 5;
  stroke-dasharray: 422, 0;
  transition: all 0.35s linear;
}

.btn-1:hover {
  font-weight: 900;
}

@keyframes background-flash {
  0%{
    width: 200px;
    height: 2rem;
    background-color: var(--header-background);
  }
  50%{
    width: 210px;
    height: 2.1rem;
    background-color: var(--light);
  }
  100%{
    width: 200px;
    height: 2rem;
    background-color: var(--header-background);
  }
}

.btn-1 .hoverAllowed:hover rect {
    stroke-width: 5;
    stroke-dasharray: 15, 310;
    stroke-dashoffset: 48;
    transition: all 2s cubic-bezier(0.19, 1, 0.22, 1);
}

.btn-1.activated {
  animation-name: background-flash;
}

/* other custom class selectors */
.bg {
  width: 100vw;
  height: 100vh;
  background: url('./background/triangles.svg') no-repeat center center;
  background-size: cover;
  position: fixed;
  top: 0;
  z-index: -1;
}

.text-column {
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-row {
  font-family: 'Quicksand', sans-serif;
  font-size: 2vmin;
  font-weight: normal;
  text-transform: capitalize;
  width: fit-content;

  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}

.header-flex-row {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  width: 100%;
  height: 50px;
  overflow: auto;
  background-color: var(--header-background);

  position: static;
  top: 0px;
  left: 0px;
  right: 0px;

  font-family: 'Quicksand', sans-serif;
  font-size: 1.5rem;
  font-weight: lighter;
  text-transform: capitalize;

  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;  
}

.header-flex-row li {
  content: "";
  padding-right: 0px;
  cursor: pointer;
  list-style: none;
}

@media only screen and (max-width: 25rem){
  .header-flex-row {
    font-size: 1.0rem;
  }
}

.mx-1 {
  margin-right: var(--spacing-one);
  margin-left: var(--spacing-one);
}

.mx-2 {
  margin-top: 0.1rem;
  margin-right: var(--spacing-five);
  margin-left: var(--spacing-five);
  margin-bottom: var(--spacing-one);
}

.mx-3 {
  position: relative;
  text-align: left;
  margin-top: 0.1rem;
  margin-right: var(--spacing-five);
  margin-left: var(--spacing-five);
  margin-bottom: 0;
  padding: 0;
  text-decoration: initial;
  border-radius: 10px;
  justify-content: center;
  align-items: center;

  width: 250px;
  height: 250px;
}

.mx-3:hover {
  box-shadow: 0px 0px 10px 5px rgba(172, 236, 255, 0.7);
  opacity: 0.7;
}

.mx-3 h1 {
  position: absolute;
  margin: 8px;
  font-size: 16px;
}

.link-panel {
  background-color: rgba(255,255,255,0.5);
  width: fit-content;
  max-width: 80vw;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 1vw;
  padding: 0;
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 200px;
  display: grid;
  justify-content: center;
  align-content: center;
}

.black-text {
  color: black;
}

.img-thumbnail {
  margin: 0;
  width: 250px;
  object-fit: contain;
  opacity: 1;
  border-radius: 10px;
  cursor: pointer;
}

@media only screen and (max-width: 375px){
  .img-thumbnail {
    width: 230px;
  }
  .mx-3 {
    width: 230px;
    height: 230px;
  }
}

.about-container {
  width: 100%;
  padding: 0 1rem;
  box-sizing: border-box;
  margin-bottom: 100px;
}

.about-text {
  margin: auto;
  width: 45rem;
  font-family: 'Quicksand', sans-serif;
  text-align: left;
}

.about-text li{
  margin-left: 3em;
  list-style: disc;
  margin-top: 0;
}

.project-text {
  width: 45rem;
  font-family: 'Quicksand', sans-serif;
  text-align: left;
  margin: 0 auto;
}

.project-text li{
  margin-top: 0.5em;
  margin-left: 3em;
  margin-right: 1em;
  list-style: disc;
}

.home-name {
  color: white;
  text-transform: uppercase;
  font-family: 'Quicksand', sans-serif;
  text-shadow: 0 0 80px rgba(255,255,255,.5);
  font-weight: 500;

  background: url('./background/gradient.png') repeat;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: aitf 10s linear infinite;

  -transform: translate3d(0,0,0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

/* Animate Background Image */
@keyframes aitf {
0% { background-position: 0% 50%; }
100% { background-position: 445% 50%; }
}


@media only screen and (min-width: 50rem)
{
  .home-name {
    font-size: 6rem;
    background-size: 60rem;
  }
}

@media only screen and (max-width: 50rem)
{
  .home-name {
    font-size: 3.8rem;
    background-size: 38rem;
  }
  .profile-row{
    padding-bottom: 50px;
  }
}

@media only screen and (max-width: 35rem)
{
 .home-name {
    font-size: 2.3rem;
    background-size: 23rem;
  }
}

@media only screen and (max-width: 45rem)
{
  .about-text {
    width: 100vw;
  }
  .project-text{
    width: 100vw;
  }
}

@media only screen and (max-width: 25rem) {
  .footer a{
    font-size: 1.4rem;
  }
}



